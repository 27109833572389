var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "font-bold text-2xl text-center mb-4" }, [
          _vm._v(_vm._s(_vm.$t("pages.help.faq.title"))),
        ]),
      ]),
    ]),
    _c("div", { attrs: { id: "help-sumally" } }, [
      _c("div", { staticClass: "container" }, [
        _c("ul", [
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _vm._m(4),
          _c("li", { staticClass: "mb-16" }, [
            _c(
              "div",
              { staticClass: "grid grid-cols-12 gap-6" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "col-span-4 col-start-2 rounded px-2 py-4 w-full relative text-center bg-base-yellow font-bold text-xl hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                    attrs: { to: "/ch/movies/create" },
                  },
                  [
                    _c("span", [_vm._v("作品単体登録")]),
                    _c("img", {
                      staticClass:
                        "absolute bottom-0 right-0 top-0 my-auto mr-2 h-4",
                      attrs: {
                        src: require("@/assets/image/iconmonstr-arrow-25.svg"),
                      },
                    }),
                  ]
                ),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "col-span-4 col-start-8 rounded px-2 py-4 w-full relative text-center bg-base-yellow font-bold text-xl hover:bg-off-yellow-lighter active:bg-off-yellow-darker",
                    attrs: { to: "/ch/movies/bulk_create" },
                  },
                  [
                    _c("span", [_vm._v("作品一括登録")]),
                    _c("img", {
                      staticClass:
                        "absolute bottom-0 right-0 top-0 my-auto mr-2 h-4",
                      attrs: {
                        src: require("@/assets/image/iconmonstr-arrow-25.svg"),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("li", { staticClass: "mb-16" }, [
            _vm._m(5),
            _c("div", { staticClass: "ml-0 md:ml-8" }, [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "よくあるご質問に関しては以下のリンクからご覧ください。"
                ),
              ]),
              _c("div", { staticClass: "w-full grid grid-cols-3 gap-6" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "col-span-1 border rounded-lg p-2 cursor-pointer relative overflow-hidden h-32",
                    attrs: { href: "/faq", target: "_blank" },
                  },
                  [
                    _c("img", {
                      staticClass: "absolute inset-0 object-cover",
                      attrs: { src: require("@/assets/image/contact_faq.jpg") },
                    }),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-white absolute bottom-2 left-2 text-shadow",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("components.pulldownModal.help.faq")
                            ) +
                            " "
                        ),
                        _c(
                          "svg",
                          {
                            staticClass: "w-5 h-5 pb-1 inline",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              "stroke-width": "1.5",
                              stroke: "currentColor",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("div", {
                      staticClass:
                        "absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-20 active:bg-black",
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mb-16" }, [
      _c("div", { staticClass: "mb-6" }, [
        _c("p", { staticClass: "text-lg font-bold" }, [
          _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
            _vm._v("1."),
          ]),
          _vm._v(" 英語字幕について "),
        ]),
      ]),
      _c("div", { staticClass: "ml-0 md:ml-8" }, [
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(" バイヤーへの提案にあたっては、"),
          _c("span", { staticClass: "bg-light-yellow" }, [
            _vm._v("基本的には英語字幕は必須"),
          ]),
          _vm._v("となります。"),
          _c("br"),
          _vm._v(
            " ただし、ご登録いただくスクリーナーについて、Youtubeの機能を使って、バイヤーが英語字幕での視聴可能な設定をしていただければ、海外バイヤーへの提案は可能です。"
          ),
          _c("br"),
          _vm._v(
            " ご参考までですが、Youtubeでの字幕の設定に関しては、下記等を参照ください。"
          ),
          _c("br"),
          _c(
            "a",
            {
              staticClass: "text-blue-600 break-all",
              attrs: {
                href: "https://support.google.com/youtube/topic/9257536?hl=ja&ref_topic=9257610&sjid=11581241402081568917-AP",
                target: "_blank",
              },
            },
            [
              _vm._v(
                "https://support.google.com/youtube/topic/9257536?hl=ja&ref_topic=9257610&sjid=11581241402081568917-AP"
              ),
            ]
          ),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "字幕ファイルのファイル形式はSRTファイルが標準にはなっていますが、TXTや、excel、docなど、他形式でもOKの場合もありますので、"
          ),
          _c("br"),
          _vm._v("作品情報登録時に入力いただければと思います。 "),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v("字幕ファイルがなく、"),
          _c("span", { staticClass: "bg-light-yellow" }, [
            _vm._v("焼き付け版しかない場合でも、販売できる場合もあります"),
          ]),
          _vm._v("ので、作品情報に入力いただければと思います。 "),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "英語字幕が存在せず「スクリーナーについてYoutubeの機能を使って、バイヤーが英語字幕での視聴可能な設定」をされた場合でも、最低限、日本語字幕ファイルの設定はおねがいいたします。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mb-16" }, [
      _c("div", { staticClass: "mb-6" }, [
        _c("p", { staticClass: "text-lg font-bold" }, [
          _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
            _vm._v("2."),
          ]),
          _vm._v(" 本編視聴用のスクリーナーについて "),
        ]),
      ]),
      _c("div", { staticClass: "ml-0 md:ml-8" }, [
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "ご登録時に、バイヤーが、本編を確認するための英語字幕付きか英語吹き替えの"
          ),
          _c("span", { staticClass: "bg-light-yellow" }, [
            _vm._v("スクリーナーをご登録いただきたく"),
          ]),
          _vm._v("お願いいたします。"),
          _c("br"),
          _vm._v(
            " ただし、ご登録いただくスクリーナーについて、Youtubeの機能を使って、バイヤーが英語字幕での視聴可能な設定をしていただければ、海外バイヤーへの提案は可能です。"
          ),
          _c("br"),
          _vm._v(
            " ご参考までですが、Youtubeでの字幕の設定に関しては、下記等を参照ください。"
          ),
          _c("br"),
          _c(
            "a",
            {
              staticClass: "text-blue-600 break-all",
              attrs: {
                href: "https://support.google.com/youtube/topic/9257536?hl=ja&ref_topic=9257610&sjid=11581241402081568917-AP",
                target: "_blank",
              },
            },
            [
              _vm._v(
                "https://support.google.com/youtube/topic/9257536?hl=ja&ref_topic=9257610&sjid=11581241402081568917-AP"
              ),
            ]
          ),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "ただし、どうしても、すぐにご用意いただけない場合（例：字幕作成中等）には、別途、"
          ),
          _c(
            "a",
            {
              staticClass: "text-blue-600 break-all",
              attrs: {
                href: "https://ec.filminatin.jp/contact",
                target: "_blank",
              },
            },
            [_vm._v("お問い合わせフォーム")]
          ),
          _vm._v("よりご相談いただければと思います。 "),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v("なお、現在、"),
          _c("span", { staticClass: "bg-light-yellow" }, [
            _vm._v("VimeoとYoutubeのみ対応しております。"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mb-16" }, [
      _c("div", { staticClass: "mb-6" }, [
        _c("p", { staticClass: "text-lg font-bold" }, [
          _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
            _vm._v("3."),
          ]),
          _vm._v(" 素材に関するご注意 "),
        ]),
      ]),
      _c("div", { staticClass: "ml-0 md:ml-8" }, [
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "本編の動画ファイルと、字幕ファイルの情報入力は、作品情報のご登録時は、必須項目ではありませんが、できるだけ、お持ちのDATAの内容をご入力いただけると、あとで間違いがすくなくて、納品も楽になります。 "
          ),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "字幕ファイル情報を、オンライン上で単体登録する場合には、「追加」ボタンを押してください。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mb-16" }, [
      _c("div", { staticClass: "mb-6" }, [
        _c("p", { staticClass: "text-lg font-bold" }, [
          _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
            _vm._v("4."),
          ]),
          _vm._v(" 納品いただいた本編素材の保存について "),
        ]),
      ]),
      _c("div", { staticClass: "ml-0 md:ml-8" }, [
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v("弊社サービスを利用できるのは、"),
          _c("span", { staticClass: "bg-light-yellow" }, [
            _vm._v("アカウントを持つお客様のみに制限しております。"),
          ]),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v("海外のバイヤーがアカウントを開設する際には"),
          _c("span", { staticClass: "bg-light-yellow" }, [
            _vm._v("弊社にて与信を行っております。"),
          ]),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "お客様がアップロードされた本編素材は、通常は、お客様と弊社運用担当者にしかアクセス権がないAWS(※1) 上の安全な場所に保存し、販売成立した場合にのみ、購入したバイヤーが、弊社サービスからのダウンロードが可能となります。その際、バイヤーがダウンロードを行うリンクURLは、リクエストをいただく度に変化し、また有効期限を制限することで、安全性を高めています。 "
          ),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _vm._v(" ※1 AWS（Amazon Web Services）:"),
          _c("br"),
          _vm._v(" Amazon社が提供しているクラウドサービスで、"),
          _c("br"),
          _vm._v("世界の金融機関や政府機関にも利用されています。"),
          _c("br"),
          _c(
            "a",
            {
              staticClass: "break-all",
              attrs: {
                href: "https://aws.amazon.com/jp/compliance/testimonials/?nc=sn&loc=9",
              },
            },
            [
              _vm._v(
                "https://aws.amazon.com/jp/compliance/testimonials/?nc=sn&loc=9"
              ),
            ]
          ),
          _c("img", {
            staticClass: "w-full max-w-xs",
            attrs: {
              src: require("@/assets/image/help/faq/aws.png"),
              alt: "AWS logo",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mb-16" }, [
      _c("div", { staticClass: "mb-6" }, [
        _c("p", { staticClass: "text-lg font-bold" }, [
          _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
            _vm._v("5."),
          ]),
          _vm._v(" 複数の作品を登録するための「一括登録」に関して "),
        ]),
      ]),
      _c("div", { staticClass: "ml-0 md:ml-8" }, [
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "ログイン後、「作品追加」の「作品一括登録」から、ご登録いただけます。一括登録ページにて、まずはexcelをダウンロードし、作品情報を記入いただいた上で、同ページでアップロードいただく形式です。 "
          ),
        ]),
        _c("p", { staticClass: "mb-3" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("●")]),
          _vm._v(
            "なお、画像の登録に時間が掛かることがございますので、ご了承ください。また、エラーになるなど、ご登録が出来なかった場合には、直接担当者にご相談ください。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-6" }, [
      _c("p", { staticClass: "text-lg font-bold" }, [
        _c("span", { staticClass: "text-2xl mx-3 text-yellow-400" }, [
          _vm._v("6."),
        ]),
        _vm._v("よくあるご質問"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }